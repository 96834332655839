import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PageTitle from '../components/PageTitle';
import QuotationsList from '../components/QuotationsList';

const ClientsPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetClientsPageQuery>(
    graphql`
      query GetClientsPage {
        file(name: { eq: "clients" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              title
              clients {
                logo {
                  childImageSharp {
                    gatsbyImageData(width: 300, layout: CONSTRAINED)
                  }
                }
                quotation
                company
                website
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  return (
    <Layout>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      <QuotationsList
        clients={pageData.clients as GatsbyTypes.MarkdownRemarkFrontmatterClients[]}
      />
    </Layout>
  );
};

export default ClientsPage;
