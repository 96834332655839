import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../graphqlTypes';
import styled, { baseline, BREAKPOINTS, scale, hachureStyles } from '../styled';

import CenteredBox from './CenteredBox';
import OutboundLink from './OutboundLink';

const Layout = styled.div``;

const Quotation = styled.div`
  position: relative;
  padding-top: ${scale(1)};
  padding-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    padding-top: ${scale(2)};
    padding-bottom: ${scale(2)};
  }

  &:not(:first-child) {
    padding-top: ${scale(3)};

    @media (min-width: ${BREAKPOINTS.s}) {
      padding-top: ${scale(4)};
    }

    &:before {
      content: '';
      ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: ${scale(2)};
    }
  }
`;

const Blockquote = styled.blockquote`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-bottom: ${scale(2)};
  }

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(3)};
  }
`;

const Client = styled.div`
  text-align: center;

  @media (min-width: ${BREAKPOINTS.s}) {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: inherit;
  }
`;

const Company = styled.div`
  @media (min-width: ${BREAKPOINTS.s}) {
    margin-right: ${scale(2)};
  }
`;

const CompanyName = styled.p`
  margin-bottom: 0;

  ${baseline(26, 2, 'm')};
  font-weight: bold;

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(36, 2, 'l')};

    margin-bottom: ${scale(1)};
  }
`;

const CompanyWebSite = styled.div`
  margin-bottom: ${scale(1)};
`;

const Logo = styled.div`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-left: ${scale(2)};

    margin-bottom: 0;
  }
`;

const QuotationsList: React.FC<{ clients: GatsbyTypes.MarkdownRemarkFrontmatterClients[] }> = ({
  clients,
  ...props
}) => {
  return (
    <Layout {...props}>
      {clients.map((client) => {
        const logo = client.logo?.childImageSharp?.gatsbyImageData;
        const website = client?.website && new URL(client.website).hostname;

        return (
          <Quotation key={String(client.company)}>
            <CenteredBox>
              <Blockquote>{client.quotation}</Blockquote>
              <Client>
                <Company>
                  <CompanyName>{client.company}</CompanyName>
                  {website && client.website && (
                    <CompanyWebSite>
                      <OutboundLink href={client.website}>{website}</OutboundLink>
                    </CompanyWebSite>
                  )}
                </Company>
                {logo && (
                  <Logo>
                    <GatsbyImage
                      image={logo}
                      alt=""
                      style={{
                        height: scale(7),
                        width: scale(7 * (logo.width / logo.height)),
                        maxHeight: scale(14 / (logo.width / logo.height)),
                        maxWidth: scale(14),
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Logo>
                )}
              </Client>
            </CenteredBox>
          </Quotation>
        );
      })}
    </Layout>
  );
};

export default QuotationsList;
